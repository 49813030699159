<template>
  <div class="box">
    <div class="flex flex_align_center title">
      <h3>订单号:</h3>
      <div class="flex_item span">{{ data.parentOrderNo }}</div>
      <img v-if="isEdit" :src="images.iconArrow" alt="" class="icon_arrow" />
    </div>
    <div class="con pr">
      <h3>反馈商品:</h3>
      <div class="flex flex_align_center">
        <img :src="data.image" alt="" />
        <div class="flex_item" style="width: 60%; margin-right: 0.2rem">
          <p class="name txtcut">{{ data.goodsTitle }}</p>
          <p class="type">
            规格：{{ newAttr.length > 0 ? newAttr[0].attrType : "" }}
          </p>
          <p class="time">下单时间：{{ data.ctime }}</p>
        </div>
        <div class="num">x{{ data.buyNum }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {},
    isEdit: {
      type: Boolean,
    },
  },
  computed: {
    newAttr: function () {
      return eval("(" + this.data.skuAttr + ")");
    },
  },
  data() {
    return {
      images: {
        iconArrow: require("@/assets/feedback/fan.png"),
      },
    };
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  .icon_arrow {
    width: 0.12rem;
    height: 0.06rem;
  }
  .title {
    padding: 0.1rem;
    border-bottom: 0.005rem solid #e5e5e5;
    h3 {
      font-size: 0.14rem;
      color: #333333;
    }
    .span {
      font-size: 0.13rem;
      color: #666666;
      margin-left: 0.06rem;
    }
  }
  .con {
    padding: 0.1rem;
    background: #fff;
    border-radius: 0.07rem;
    h3 {
      font-size: 0.14rem;
      color: #333333;
      margin-bottom: 0.1rem;
    }
    .time {
      font-size: 0.14rem;
      color: #333333;
      margin-bottom: 0.05rem;
    }
    img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.1rem;
    }
    .name {
      font-size: 0.13rem;
      color: #333333;
      margin-bottom: 0.03rem;
      font-weight: 400;
    }
    .type {
      font-size: 0.1rem;
      color: #666666;
      margin-bottom: 0.03rem;
    }
    .time {
      font-size: 0.1rem;
      color: #666666;
    }
    .num {
      font-size: 0.13rem;
      color: #666666;
    }
    .state {
      right: 0;
      top: 0;
      background: #fae2df;
      border-radius: 0 0.07rem;
      font-size: 0.1rem;
      color: #dc2727;
      text-align: center;
      width: 0.41rem;
      height: 0.2rem;
      line-height: 0.2rem;
      &.online {
        background: #e5efff;
        color: #246ede;
      }
    }
  }
}
</style>
