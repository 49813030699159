import request from '@/lib/request'

//意见反馈-获取订单列表
export function getProList(data){
  return request({
    url: '/userQuestions/searchUserGoodsInfos',
    method: 'get',
    params:data,
    timeout: 60000 // 60秒
  })
}

//意见反馈-上传附件（图片）
export function fileUpload(data){
  return request({
    url:'/userQuestions/fileUpload',
    method: 'post',
    data:data
  })
}

//意见反馈-提交
export function userQuestionsSave(data){
  return request({
    url:'/userQuestions/userQuestionsSave',
    method:'post',
    data:data
  })
}

//意见反馈-详情
export function getFeedbackDetail(id) {
  return request({
    url: '/userQuestions/userQuestion?id=' + id,
    method: 'get'
  })
}

//意见反馈-列表
export function getFeedbackList(data){
  return request({
    url:'/userQuestions/userQuestionsSearch',
    method:'get',
    params:data
  })
}

//意见反馈-获取二级类目
export function getSecondClass(data){
  return request({
    url:'userQuestions/cateGoryType',
    method: 'get',
    params:data
  })
}

//意见反馈-详情-客服反馈
export function getReplayContent(id){
  return request({
    url: 'userQuestions/allReplySelect?id=' + id,
    method: 'get'
  })
}
