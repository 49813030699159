<template>
  <div class="flex flex_align_center con">
    <img :src="newImg" alt="" />
    <div class="flex_item flex flex_align_center" style="width: 60%">
      <div class="flex_item" style="width: 60%">
        <h3 class="txtcut" style="width: 95%">{{ data.goodsTitle }}</h3>
        <p class="type">
          规格： {{ newAttr.length > 0 ? newAttr[0].attrType : "" }}
        </p>
        <p>下单时间：{{ data.ctime }}</p>
      </div>
      <div class="number">x{{ data.buyNum }}</div>
    </div>
    <div class="icon_radio" :class="{ ac: data.isSelect }"></div>
  </div>
</template>
<script>
import defaultImg from '@/assets/feedback/default.png';
export default {
  props: {
    data: {
      defaultImg
    },
  },
  computed: {
    newAttr: function () {
      return eval("(" + this.data.skuAttr + ")");
    },
    newImg:function(){
      return this.data.image ? this.data.image : defaultImg;
    }
  },
};
</script>
<style lang="less" scoped>
.con {
  margin-bottom: 0.05rem;
  img {
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.1rem;
  }
  h3 {
    font-size: 0.13rem;
    color: #333333;
    margin-bottom: 0.03rem;
  }
  p {
    font-size: 0.1rem;
    color: #666666;
    margin-bottom: 0.03rem;
  }
  .number {
    font-size: 0.13rem;
    color: #666666;
  }
  .icon_radio {
    background: url("../../../assets/feedback/radio.png") no-repeat;
    background-size: 100% 100%;
    width: 0.15rem;
    height: 0.15rem;
    margin-left: 0.4rem;
    &.ac {
      background: url("../../../assets/feedback/radio-select.png");
      background-size: 100% 100%;
      width: 0.15rem;
      height: 0.15rem;
    }
  }
}
</style>
