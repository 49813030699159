<template>
    <div class="sub-title">
        <span v-if="isRequired">*</span>{{title}}
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String
        },
      isRequired:{
        type:Boolean,
        default: true
      },
    }
}
</script>
<style lang="less" scoped>
.sub-title{
    font-size:.14rem;
    color: #333;
    span{
        color: #DC2727;
    }
}
</style>
