<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoadScroll()"
      >
        <div
          class="content"
          v-for="(item, index) in proList"
          :key="'item' + index"
        >
          <div class="flex flex_align_center title">
            <div class="flex_item">订单号：</div>
            <span>{{ item.parentOrderNo  }}</span>
          </div>
          <div
            v-for="(items, indexs) in item.children"
            :key="indexs"
            @click="selectPro(index, indexs)"
          >
            <SelectListCell :data="items" />
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getProList } from "@/api/feedback/base";
import SelectListCell from "./SelectListCell.vue";

export default {
  components: {
    SelectListCell,
  },
  props: {
    proList: {},
  },
  data() {
    return {
      radio: "1",
      piImgList: [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }],
      pageIndex: 1,
      pageSize: 5,
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {
    selectPro(index, ins) {
      this.$emit("onSelectPro", index, ins);
    },
    onLoadScroll() {
      this.getList();
    },
    getList() {
      getProList({
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        let { total, rows } = res;
        if (this.refreshing) {
          this.dataList = [];
          this.refreshing = false;
        }
        if (this.pageIndex == 1) {
          this.dataList = rows;
        } else {
          rows.forEach((item) => {
            this.dataList.push(item);
          });
        }
        this.$emit("addProList", this.dataList);
        this.loading = false;

        if (this.dataList.length >= total) {
          this.finished = true;
        }
        this.pageIndex += 1;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageIndex = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoadScroll();
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 0 0.2rem 0.17rem;
  border-bottom: 0.005rem solid #e5e5e5;
  .title {
    font-size: 0.15rem;
    color: #000;
    margin: 0.15rem 0 0.1rem;
    span {
      font-size: 0.13rem;
      color: #666666;
    }
  }
}
</style>
