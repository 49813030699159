<template>
  <div class="feedback_upload">
    <van-uploader
      :upload-icon="iconCamera"
      :upload-text="'添加照片'"
      v-model="proImgListNew"
      :max-count="6"
      :show-upload="isEdit"
      :deletable="isEdit"
      :after-read="uploadImg"
      :before-delete="beforeDelete"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { Uploader } from "vant";
import { fileUpload } from "@/api/feedback/base.js";

Vue.use(Uploader);
export default {
  props: {
    proImgList: {
      type: Array,
    },
    isEdit: {
      type: Boolean,
    },
  },
  watch:{
    proImgList(newVal){
      this.proImgListNew = newVal;
    }
  },
  created(){
    this.proImgListNew = this.proImgList;
  },
  data() {
    return {
      iconCamera: require("@/assets/feedback/icon-camera.png"),
      proImgListNew:[]
    };
  },
  methods: {
    uploadImg(file) {
      // 大于1.5MB的jpeg和png图片都缩小像素上传
      if (/\/(?:jpeg|png)/i.test(file.file.type) && file.file.size > 150 * 1024) {
        console.log('进行压缩')
        // 创建新的图片对象
        let img = new Image();
        // 指定图片的DataURL(图片的base64编码数据)
        img.src = file.content;
        // 监听浏览器加载图片完成，然后进行绘制
        img.onload = () => {
          let dataURL = this.scaleImg(img, {
            maxWidth: 1000,
            maxHeight: 1000,
          }).dataURL;
          // baser64 TO blob,将base64转为file
          let myFile = this.dataURLtoFile(dataURL, file.file.name);

          let formDatas = new FormData();
          formDatas.append("file", myFile);
          fileUpload(formDatas).then((res) => {
            this.$emit("addImg", {
              url: res.data,
            });
          });
        };
      } else {
        this.uploadFile(file);
      }
    },
    //将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    uploadFile(file) {
      // 此时可以自行将文件上传至服务器
      let formdata = new FormData();
      formdata.append("file", file.file);
      fileUpload(formdata).then((res) => {
        this.$emit("addImg", {
          url: res.data,
        });
      });
    },
    beforeDelete(e) {
      this.$emit("removeImg", e.url);
    },
    //同比压缩图片
    scaleImg: function (img, defaults) {
      // 缩放图片需要的canvas
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      // 图片原始尺寸
      var originWidth = img.width;
      var originHeight = img.height;
      // 最大尺寸限制，可通过国设置宽高来实现图片压缩程度
      var maxWidth = defaults.maxWidth,
        maxHeight = defaults.maxHeight;
      // 目标尺寸
      var targetWidth = originWidth,
        targetHeight = originHeight;
      // 图片尺寸超过400x400的限制
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          // 更宽，按照宽度限定尺寸
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originHeight / originWidth));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originWidth / originHeight));
        }
      }
      // canvas对图片进行缩放
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      // 清除画布
      context.clearRect(0, 0, targetWidth, targetHeight);
      // 图片压缩
      context.drawImage(img, 0, 0, targetWidth, targetHeight);
      /*第一个参数是创建的img对象；第二个参数是左上角坐标，后面两个是画布区域宽高*/
      //压缩后的图片base64 url
      /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/jpeg';
       * qualityArgument表示导出的图片质量，只要导出为jpg和webp格式的时候此参数才有效果，默认值是0.92*/
      let dataURL = canvas.toDataURL("image/jpeg");
      //回调函数用以向数据库提交数据
      var base64 = dataURL.substr(dataURL.indexOf(",") + 1);
      return { dataURL, base64 };
    },
  },
};
</script>
<style lang="less">
// 重写van-upload-css
.feedback_upload {
  .van-uploader__preview {
    border-radius: 0.1rem;
    overflow: hidden;
    box-shadow: 0 0.015rem 0.03rem rgba(0, 0, 0, 0.1);
  }
  .van-uploader__upload {
    border-radius: 0.1rem;
    overflow: hidden;
    background: #f8f8f8;
    box-shadow: 0 0.015rem 0.03rem rgba(0, 0, 0, 0.1);
  }
  .van-uploader__preview-delete {
    width: 0.21rem;
    height: 0.21rem;
    border-radius: 0;
    background: #b3b3b3;
  }
  .van-uploader__preview-delete-icon {
    right: 0.02rem;
    top: 0.02rem;
    transform: scale(1);
  }
}
.UploadImg{
  .van-uploader__upload {
    background: #fff;
    border: 1px dashed #999;
    box-shadow: none;
  }
}
</style>
